import React, { useState, useEffect, useCallback, useRef, useContext, useMemo } from "react";
import Link from "../Link";
import { formatStringForId, formatForTOC } from "../../services/URL";
import { onHeaderChanged, offHeaderChanged } from "../../services/DOMService";
import Classnames from "classnames";
import Button from "../Button";
import { graphql, useStaticQuery } from "gatsby";

import "./TableOfContents.scss";
import "./TableOfContents.mobile.scss";
import IconLink from "../IconLink/IconLink";
import SqueezeToFit from "../SqueezeToFit";
import PlatformContext from "../../context/PlatformContext";


// Smooth scrolls to id with offset given via props
function scrollToIdOffset(id) {
  // Properly escapes the ID if it starts with a digit
  const escapedId = id.replace(/^(\d)/, '\\3$1 ');
  const selector = `:not(.unselected-platform) #${escapedId}, :not(.unselected-platform) #${escapedId} *`;
  const element = document.querySelector(selector);
  if (!element) return;
  const newOffset = element.getBoundingClientRect().top + window.pageYOffset - 64;
  window.scrollTo({
    top: newOffset,
  });
}

function TableOfContents(props) {
  const {
    Desktop,
    Mobile,
    Tablet,
    blogData,
    noTOC,
    downloadLink,
    downloadTitle,
    otherLinks,
    currentTab,
    toc: tableOfContents,
  } = props;

  const [active, setActive] = useState("");
  const { platform } = useContext(PlatformContext);
  const handleHeaderChange = useCallback((value) => {
    setActive(value);
  }, []);

  const getToc = useCallback(() => {
    const query = document.querySelectorAll(
      ".HTMLToReact h2:not(.unselected-platform *), .HTMLToReact h3:not(.unselected-platform *)"
    );
    const toc = Array.from(query).map((item) => ({
      value: item.attributes.getNamedItem("pdftron-tag")?.nodeValue || "",
      depth: item.tagName === "H2" ? 2 : 3,
    }));
    return toc;
  }, [currentTab, platform]);


  useEffect(() => {
    if (!window || !document) return;
    // @ts-ignore
    onHeaderChanged(handleHeaderChange, "headerListener");
    const hash = window.location.hash.replace("#", "");
    const found = getToc().find((item) => formatStringForId(item.value) === hash);
    if (found) {
      setActive(found.value);
    }

    return () => {
      // @ts-ignore
      offHeaderChanged(handleHeaderChange, "headerListener");
    };
  }, []);

  const c = Classnames({
    TableOfContents: true,
    Desktop,
    Mobile,
    Tablet,
  });

  let url = null;
  let title = null;
  if (blogData && blogData.node) {
    url = blogData.node.frontmatter.url || `/blog${blogData.node.fields.slug}`;
    title = `Blog: ${blogData.node.frontmatter.title}`;
  }

  return (
    <div className={c}>
      <SqueezeToFit className={`inner`}>
        <div>
          {!noTOC && tableOfContents.length > 0 && (
            <React.Fragment>
              <p className="table-of-contents-header">Content</p>
              {tableOfContents.length > 0 &&
                tableOfContents.map(({ value, depth }, i) => {
                  const formatted = formatStringForId(value);

                  const isActive = value === active || (active === "" && i === 0);

                  return (
                    <div
                      key={value}
                      className={`heading heading${depth} ${isActive ? "active" : ""}`}
                    >
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToIdOffset(formatted);
                          setActive(value);
                          window.history.pushState(null, null, `#${formatted}`);
                        }}
                        to={`#${formatted}`}
                        style={{ margin: 0, cursor: "pointer" }}
                        indigo={isActive}
                      >
                        {formatForTOC(value)}
                      </Link>
                    </div>
                  );
                })}
            </React.Fragment>
          )}

          {otherLinks && (
            <div className="other-content">
              {otherLinks.map((link) => {
                const split = link.split("|");
                return <IconLink key={link} to={split[0]} title={split[1]} />;
              })}
            </div>
          )}

          {downloadLink && (
            <Button className="download-link" to={downloadLink} newTab>
              {downloadTitle}
            </Button>
          )}
        </div>
      </SqueezeToFit>
    </div>
  );
}

const Nav = (props) => {
  const { blog, platform, platforms } = props;

  const data = useStaticQuery(graphql`
    query DocBlogTemplate {
      related: allMarkdownRemark(filter: { frontmatter: { docId: { ne: null } } }) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              summary
              images
              url
              docId
            }
          }
        }
      }
    }
  `);

  if (!blog) {
    return <TableOfContents {...props} />;
  }

  if (typeof blog === "string") {
    throw new Error("Blog must be an array");
  }

  let searchBlog = blog[0];
  if (blog.length > 1) {
    const searchIndex = platforms.split(" ").findIndex((f) => f.trim() === platform.trim());
    searchBlog = (blog[searchIndex] || "").trim();
  }

  const blogData = data.related.edges.find((edge) => edge.node.frontmatter.docId === searchBlog);

  return <TableOfContents {...props} blogData={blogData} />;
};

export default Nav;
